'use client'

import { isServer } from '@tebuto/functions/nextjs'
import { Router } from 'next/router'
import Script from 'next/script'
import { useEffect, useRef, useState } from 'react'

const siteID = 2
const trackerUrl = 'https://schroeer.matomo.cloud/'
const scriptSrc = 'https://cdn.matomo.cloud/schroeer.matomo.cloud/matomo.js'

declare global {
    interface Window {
        _mtm: any[]
        _paq: any[]
    }
}

export function MatomoProvider() {
    useEffect(() => {
        Router.events.on('routeChangeComplete', Matomo.trackView)
        return () => Router.events.off('routeChangeComplete', Matomo.trackView)
    }, [])

    return <Matomo.code />
}

export namespace Matomo {
    export function code() {
        return (
            <Script
                strategy="afterInteractive"
                async
                defer
                // biome-ignore lint/security/noDangerouslySetInnerHtml: We dont care about this code here
                dangerouslySetInnerHTML={{
                    __html: `
                    var _paq = window._paq = window._paq || [];
                    _paq.push(['disableCookies']);
                    _paq.push(['trackPageView']);
                    _paq.push(['enableLinkTracking']);
                    (function() {
                    var u='${trackerUrl}';
                    _paq.push(['setTrackerUrl', u+'matomo.php']);
                    _paq.push(['setSiteId', ${siteID}]);
                    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                    g.async=true; g.src='${scriptSrc}'; s.parentNode.insertBefore(g,s);
                    })();
                    `
                }}
            />
        )
    }

    export enum EventCategory {
        BUTTON = 'Button',
        FORM = 'Form',
        VIDEO = 'Video',
        SUBSCRIPTION = 'Subscription',
        SCROLL = 'Scroll'
    }

    export enum EventAction {
        CLICK = 'Click',
        SUBMIT = 'Submit',
        PLAY = 'Play',
        PAUSE = 'Pause',
        PAYMENT = 'Payment',
        SECTION_REACHED = 'SectionReached'
    }

    interface TrackEventOptions {
        category: EventCategory
        action: EventAction
        name?: string
        value?: number
    }

    export function trackEvent({ category, action, name = '', value = 0 }: TrackEventOptions) {
        if (typeof window !== 'undefined' && window._paq) {
            window._paq.push(['trackEvent', category, action, name, value])
        }
    }

    export function trackView(url: string) {
        if (window._paq) {
            window._paq.push(['setCustomUrl', url])
            window._paq.push(['trackPageView'])
        }
    }

    interface UseInViewportOptions {
        threshold?: number
        root?: Element | null
        rootMargin?: string
    }

    /**
     * Hook to check if an element is in the viewport.
     * @param options Intersection Observer options
     * @returns [ref, isInView] - Ref to attach to the element and its visibility status
     */
    const useInViewport = (options?: UseInViewportOptions): [React.RefObject<HTMLDivElement>, boolean] => {
        const ref = useRef<HTMLDivElement>(null)
        const [isInView, setIsInView] = useState(false)

        useEffect(() => {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    setIsInView(entry.isIntersecting)
                },
                {
                    threshold: options?.threshold || 0.1,
                    root: options?.root || null,
                    rootMargin: options?.rootMargin || '0px'
                }
            )

            if (ref.current) {
                observer.observe(ref.current)
            }

            return () => {
                if (ref.current) {
                    observer.unobserve(ref.current)
                }
            }
        }, [options])

        return [ref, isInView]
    }

    interface ScrollTrackingAnchorProps {
        sectionName: string
        threshold?: number
        root?: Element | null
        rootMargin?: string
    }

    export function ScrollTrackingAnchor({ sectionName, threshold = 0.1, root = null, rootMargin = '0px' }: ScrollTrackingAnchorProps) {
        const [ref, isInView] = useInViewport({ threshold, root, rootMargin })

        useEffect(() => {
            if (isInView) {
                trackEvent({
                    category: EventCategory.SCROLL,
                    action: EventAction.SECTION_REACHED,
                    name: sectionName
                })
            }
        }, [isInView, sectionName])

        return <div ref={ref} />
    }
}
